<template>
  <div>
    <PageHeader />
    <b-card>
      <PromotionCodeForm />
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'PromotionCodeCreatePage',
  page: {
    title: 'สร้างโค้ดโปรโมชั่น',
  },
  components: {
    PromotionCodeForm: () => import('@components/promotion-code/Form')
  }
}
</script>

<style scoped>

</style>